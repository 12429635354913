<template>
  <c-box
    id="kuisioner"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Formulir Gizi', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      bg="#FFF"
      :p="['1rem', '0px']"
    >
      <c-box
        w="100%"
        max-width="1200px"
        mx="auto"
      >
        <Steps
          :current="5"
          :total="6"
        />
        <c-box
          w="100%"
          max-width="590px"
          mx="auto"
          :margin-top="['16px', '24px']"
        >
          <c-box text-align="center">
            <c-text
              color="primary.400"
              font-family="roboto"
              text-transform="uppercase"
              font-weight="500"
              :font-size="['16px', '18px']"
              margin-bottom="10px"
            >
              Formulir Gizi (5/6)
            </c-text>
            <c-heading
              font-weight="700"
              :font-size="['18px', '28px']"
              :line-height="['27px', '42px']"
              color="black.900"
            >
              Riwayat Makan 24 Jam Terakhir
            </c-heading>
          </c-box>

          <form
            v-chakra
            marginTop="24px"
            @submit.prevent="submit"
          >
            <c-text
              font-family="Roboto, sans-serif"
              :font-weight="['700', '500']"
              :font-size="['14px', '18px']"
              color="#333333"
              :pb="['16px','20px']"
            >
              Silahkan tuliskan makanan yang kamu konsumsi hari ini, juga termasuk minuman selain air mineral <c-text
                as="span"
                color="#D32737"
              >
                *
              </c-text>
            </c-text>
            <FormAccordionList allow-toggle>
              <FormAccordionListItem
                label="Pagi"
                :is-error="!isSectionPagiValid"
              >
                <FormFood
                  v-if="breakfast_"
                  label="Makan Pagi"
                  time="Pukul 00:00 - 09:00"
                  placeholder-type="food"
                  :is-eat="breakfast_.isEat"
                  :has-photo="breakfast_.hasPhoto"
                  :description-food="breakfast_.descriptionFood"
                  :photos="breakfast_.photos"
                  :is-description-food-valid="!$v.breakfast_.descriptionFood.$invalid"
                  @update:is-eat="(newValue) => breakfast_.isEat = newValue"
                  @update:has-photo="(newValue) => breakfast_.hasPhoto = newValue"
                  @update:description-food="(newValue) => breakfast_.descriptionFood = newValue"
                  @update:photos="(newValue) => breakfast_.photos = newValue"
                />

                <FormFood
                  v-if="morningSnack_"
                  label="Snack/cemilan Pagi"
                  time="Pukul 10:00 - 11:00"
                  placeholder-type="snack"
                  :is-eat="morningSnack_.isEat"
                  :has-photo="morningSnack_.hasPhoto"
                  :description-food="morningSnack_.descriptionFood"
                  :photos="morningSnack_.photos"
                  style="margin-top: 16px"
                  :is-description-food-valid="!$v.morningSnack_.descriptionFood.$invalid"
                  @update:is-eat="(newValue) => morningSnack_.isEat = newValue"
                  @update:has-photo="(newValue) => morningSnack_.hasPhoto = newValue"
                  @update:description-food="(newValue) => morningSnack_.descriptionFood = newValue"
                  @update:photos="(newValue) => morningSnack_.photos = newValue"
                />
              </FormAccordionListItem>
              <FormAccordionListItem
                label="Siang"
                :is-error="!isSectionSiangValid"
              >
                <FormFood
                  v-if="lunch_"
                  label="Makan Siang"
                  time="Pukul 12:00 - 14:00"
                  placeholder-type="food"
                  :is-eat="lunch_.isEat"
                  :has-photo="lunch_.hasPhoto"
                  :description-food="lunch_.descriptionFood"
                  :photos="lunch_.photos"
                  :is-description-food-valid="!$v.lunch_.descriptionFood.$invalid"
                  @update:is-eat="(newValue) => lunch_.isEat = newValue"
                  @update:has-photo="(newValue) => lunch_.hasPhoto = newValue"
                  @update:description-food="(newValue) => lunch_.descriptionFood = newValue"
                  @update:photos="(newValue) => lunch_.photos = newValue"
                />

                <FormFood
                  v-if="afternoonSnack_"
                  label="Snack/cemilan Siang"
                  time="Pukul 15:00 - 17:00"
                  placeholder-type="snack"
                  :is-eat="afternoonSnack_.isEat"
                  :has-photo="afternoonSnack_.hasPhoto"
                  :description-food="afternoonSnack_.descriptionFood"
                  :photos="afternoonSnack_.photos"
                  style="margin-top: 16px"
                  :is-description-food-valid="!$v.afternoonSnack_.descriptionFood.$invalid"
                  @update:is-eat="(newValue) => afternoonSnack_.isEat = newValue"
                  @update:has-photo="(newValue) => afternoonSnack_.hasPhoto = newValue"
                  @update:description-food="(newValue) => afternoonSnack_.descriptionFood = newValue"
                  @update:photos="(newValue) => afternoonSnack_.photos = newValue"
                />
              </FormAccordionListItem>
              <FormAccordionListItem
                label="Malam"
                :is-error="!isSectionMalamValid"
              >
                <FormFood
                  v-if="dinner_"
                  label="Makan Malam"
                  time="Pukul 18:00 - 20:00"
                  placeholder-type="food"
                  :is-eat="dinner_.isEat"
                  :has-photo="dinner_.hasPhoto"
                  :description-food="dinner_.descriptionFood"
                  :photos="dinner_.photos"
                  :is-description-food-valid="!$v.dinner_.descriptionFood.$invalid"
                  @update:is-eat="(newValue) => dinner_.isEat = newValue"
                  @update:has-photo="(newValue) => dinner_.hasPhoto = newValue"
                  @update:description-food="(newValue) => dinner_.descriptionFood = newValue"
                  @update:photos="(newValue) => dinner_.photos = newValue"
                />

                <FormFood
                  v-if="nightSnack_"
                  label="Snack/cemilan Malam"
                  time="Pukul 21:00 - 00:00"
                  placeholder-type="snack"
                  :is-eat="nightSnack_.isEat"
                  :has-photo="nightSnack_.hasPhoto"
                  :description-food="nightSnack_.descriptionFood"
                  :photos="nightSnack_.photos"
                  style="margin-top: 16px"
                  :is-description-food-valid="!$v.nightSnack_.descriptionFood.$invalid"
                  @update:is-eat="(newValue) => nightSnack_.isEat = newValue"
                  @update:has-photo="(newValue) => nightSnack_.hasPhoto = newValue"
                  @update:description-food="(newValue) => nightSnack_.descriptionFood = newValue"
                  @update:photos="(newValue) => nightSnack_.photos = newValue"
                />
              </FormAccordionListItem>
            </FormAccordionList>

            <c-flex
              gap="3"
              margin-top="16px"
            >
              <c-button
                w="100%"
                h="48px"
                variant-color="primary"
                variant="outline"
                border-radius="100px"
                @click="onPrevious"
              >
                Sebelumnya
              </c-button>
              <c-button
                type="submit"
                w="100%"
                h="48px"
                border-radius="100px"
                variant-color="primary"
                variant="solid"
                loading-text="Submitting"
                :is-disabled="$v.$invalid"
                :is-loading="isSubmitting"
              >
                Selanjutnya
              </c-button>
            </c-flex>
          </form>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import Steps from '@/components/steps'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import imageCompression from 'browser-image-compression'
import FormFood from '@/views/client/kuisioner/forms/form-food.vue'
import { requiredIf } from 'vuelidate/lib/validators'
import { isDataEmpty } from '@/utils/is-data-empty'
import FormAccordionList from '@/views/client/kuisioner/forms/form-accordion/list.vue'
import FormAccordionListItem from '@/views/client/kuisioner/forms/form-accordion/list-item.vue'

export default {
  name: 'FormulirGizi5Page',
  components: {
    FormAccordionListItem,
    FormAccordionList,
    FormFood,
    BreadcrumbPath,
    Steps,
  },
  data() {
    return {
      breakfast_: {
        isEat: true,
        hasPhoto: true,
        descriptionFood: '',
        photos: ['', ''],
      },
      morningSnack_: {
        isEat: true,
        hasPhoto: true,
        descriptionFood: '',
        photos: ['', ''],
      },
      lunch_: {
        isEat: true,
        hasPhoto: true,
        descriptionFood: '',
        photos: ['', ''],
      },
      afternoonSnack_: {
        isEat: true,
        hasPhoto: true,
        descriptionFood: '',
        photos: ['', ''],
      },
      dinner_: {
        isEat: true,
        hasPhoto: true,
        descriptionFood: '',
        photos: ['', ''],
      },
      nightSnack_: {
        isEat: true,
        hasPhoto: true,
        descriptionFood: '',
        photos: ['', ''],
      },
      isSubmitting: false,
      questions: [],
    }
  },
  validations() {
    return {
      breakfast_: {
        descriptionFood: { required: requiredIf((v) => v?.isEat && !v?.hasPhoto) },
        photos: { photoValidation: this.photoValidation },
      },
      morningSnack_: {
        descriptionFood: { required: requiredIf((v) => v?.isEat && !v?.hasPhoto) },
        photos: { photoValidation: this.photoValidation },
      },
      lunch_: {
        descriptionFood: { required: requiredIf((v) => v?.isEat && !v?.hasPhoto) },
        photos: { photoValidation: this.photoValidation },
      },
      afternoonSnack_: {
        descriptionFood: { required: requiredIf((v) => v?.isEat && !v?.hasPhoto) },
        photos: { photoValidation: this.photoValidation },
      },
      dinner_: {
        descriptionFood: { required: requiredIf((v) => v?.isEat && !v?.hasPhoto) },
        photos: { photoValidation: this.photoValidation },
      },
      nightSnack_: {
        descriptionFood: { required: requiredIf((v) => v?.isEat && !v?.hasPhoto) },
        photos: { photoValidation: this.photoValidation },
      },
    }
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.foodRecords,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    ...mapGetters({
      axios: 'axios',
    }),
    isSectionPagiValid() {
      return !this.$v.breakfast_.$invalid && !this.$v.morningSnack_.$invalid
    },
    isSectionSiangValid() {
      return !this.$v.lunch_.$invalid && !this.$v.afternoonSnack_.$invalid
    },
    isSectionMalamValid() {
      return !this.$v.dinner_.$invalid && !this.$v.nightSnack_.$invalid
    },
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (isDataEmpty(val)) return

        this.breakfast_ = val.breakfast
        this.lunch_ = val.lunch
        this.dinner_ = val.dinner
        this.afternoonSnack_ = val.afternoonSnack
        this.morningSnack_ = val.morningSnack
        this.nightSnack_ = val.nightSnack
      },
    },
    $data: {
      handler: _.debounce(function() {
        this.setStoreFoodRecords({
          breakfast: this.breakfast_,
          morningSnack: this.morningSnack_,
          lunch: this.lunch_,
          afternoonSnack: this.afternoonSnack_,
          dinner: this.dinner_,
          nightSnack: this.nightSnack_,
        })
      }, 1000),
      deep: true,
    },
  },
  created() {
    if (this.currentStep && this.currentStep != 5) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    } else if (this.currentStep == null) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/statement`)
    }
  },
  mounted() {
    this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'foodRecords',
    })
  },
  methods: {
    ...mapMutations({
      setStoreFoodRecords: 'kuisioner/setFoodRecords',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    photoValidation(value, parentValue) {
      if (!parentValue?.hasPhoto || !parentValue?.isEat) {
        return true
      }
      return value.some((v) => !isDataEmpty(v))
    },
    async submit() {
      try {
        this.isSubmitting = true
        let value = _.cloneDeep({
          breakfast: this.breakfast_,
          morningSnack: this.morningSnack_,
          lunch: this.lunch_,
          afternoonSnack: this.afternoonSnack_,
          dinner: this.dinner_,
          nightSnack: this.nightSnack_,
        })

        let arrPromiseImageCompress = []
        let arrPromiseUploadPhotos = []
          
        for (let key in value) {
          if (!value[key]?.isEat || !value[key]?.hasPhoto) {
            // value[key].photos = []
            continue
          }

          for (let photo of value[key]?.photos || []) {
            if (isDataEmpty(photo)) {
              arrPromiseUploadPhotos.push(photo)
              continue
            }
            if (typeof photo === 'string') {
              arrPromiseUploadPhotos.push(photo)
              continue
            }

            let options = {
              maxSizeMB: 0.5,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            }
            arrPromiseImageCompress.push(imageCompression(photo.file, options))
          }

          const compressedFiles = await Promise.all(arrPromiseImageCompress)

          for (let compressedFile of compressedFiles) {
            let formData = new FormData()
            let fileName = compressedFile.name
            let file = new File([compressedFile], fileName)
            formData.append('file', file, fileName)

            let url = this.axios
              .post('/v1/clients/questionnaire/upload', formData)
              .then((r) => r.data.data?.url)
            arrPromiseUploadPhotos.push(url)
          }

          const res = await Promise.all(arrPromiseUploadPhotos)
          value[key].photos = res

          arrPromiseImageCompress = []
          arrPromiseUploadPhotos = []
        }

        await this.$store.dispatch('kuisioner/setFoodRecords', {
          programId: this.$route.params.programId,
          isDraft: true,
          value,
          answers: [],
          step: 6,
        })

        await this.$store.dispatch('kuisioner/updateCurrentStep', 6)
        await this.$store.commit('kuisioner/resetFoodRecords')
        await this.$router.push({
          name: 'client.kuisioner6',
          params: this.$route.params,
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
      } finally {
        this.isSubmitting = false
      }
    },
    async onPrevious() {
      if (this.isSubmitting) return
      await this.$store.dispatch('kuisioner/updateCurrentStep', 4)
      await this.$store.commit('kuisioner/resetFoodRecords')
      await this.$router.push({
        name: 'client.kuisioner4',
        params: this.$route.params,
      })
    },
  },
}
</script>
